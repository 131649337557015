/* Base styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}

/* Dark mode styles */
body.dark-mode {
  background-color: #282c34;
}

/* Light mode styles */
body.light-mode {
  background-color: #ffffff;
}

