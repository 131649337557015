.App {
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  transition: background-color 0.3s, color 0.3s;
  margin-left: auto;
  margin-right: auto;
}

.light-mode {
  background-color: #fafaf9;
  color: #000000;
}

.dark-mode {
  background-color: #282c34;
  color: #ffffff;
}

/* Header Styling */
header {
  padding: 20px;
  text-align: right;
  color: fafaf9;
}

.header-light {
  background-color: #fafaf9;
  color: #000000;
}

.header-dark {
  background-color: #282c34;
  color: #ffffff;
}

/* Header Container */
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.header-container h1 {
  margin: 0;
  font-size: 1.5rem;
}

.header-container nav {
  display: flex;
  align-items: center;
}

.header-container nav a {
  margin-left: 20px;
  text-decoration: none;
  color: inherit;
}

.header-container nav a:hover {
  text-decoration: underline;
}

.header-container nav img {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

/* Theme Toggle Button */
.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 20px;
  padding: 0;
  outline: none;
}

/* General styles for the theme toggle button */
.theme-toggle img {
  width: 24px;
  height: 24px;
  transition: filter 0.3s; /* Smooth transition when switching modes */
}

/* Apply filter to make the image white in dark mode */
.dark-mode .theme-toggle img {
  filter: invert(1);
}

/* No filter for light mode */
.light-mode .theme-toggle img {
  filter: none;
}

/* Section Styling */
section {
  margin: 20px;
}

section h2 {
  border-bottom: 2px solid #b7bcbd;
  padding-bottom: 10px;
}

/* About Me Section */
#about {
  margin-left: 50px; /* Adjust as needed for wider margins */
  margin-right: 50px; /* Adjust as needed for wider margins */
}

.about-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 20px;
  align-items: center;
  font-size: medium;
}

.about-grid img {
  width: 100%;
  border-radius: 5px;
}

/* Contact Me Section */
#contact {
  margin-left: 40px; /* Adjust as needed for wider margins */
  margin-right: 40px; /* Adjust as needed for wider margins */
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-container nav {
    flex-direction: row;
    justify-content: center; /* Center the navbar items on mobile */
    width: 100%; /* Ensure the navbar takes full width */
  }

  .header-container nav a {
    margin: 10px 10px; /* Increase space between the navbar items */
  }

  .header-container h1 {
    font-size: 1.25rem;
    margin-bottom: 10px;
    text-align: center; /* Center the header title on mobile */
  }

  .about-grid {
    grid-template-columns: 1fr; /* Stack columns on mobile */
    text-align: center; /* Center text on mobile */
  }

  .about-grid img {
    margin: 0 auto;
    width: 80%; /* Adjust the image size for smaller screens */
  }

  section h2 {
    text-align: center; /* Center section titles on mobile */
  }
}

.resume-download-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #2384c0;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.resume-download-link:hover {
  background-color: #4cb3f3;
}

/* Footer Styling */
footer {
  padding: 20px;
  text-align: center;
  background-color: inherit; /* Match the background color with the current mode */
  color: inherit; /* Match the text color with the current mode */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition between modes */
}

.light-mode footer {
  background-color: #fafaf9;
  color: #000000;
}

.dark-mode footer {
  background-color: #282c34;
  color: #ffffff;
}

.padding {
  padding: 20px;
}